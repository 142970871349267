import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DicaExtra from "../components/DicaExtra"

import * as S from "../components/Pages/general"

const PolicesPage = () => (
  <Layout>
    <SEO
      title="Política de privacidade"
      description="Nessa página trazemos de forma total e transparente tudo o que fazemos e como fazemos no nosso site, o intuito aqui é ser o mais sincero com vocês e apresentar o melhor que há."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle align="left" font>
        Política de privacidade
      </S.GeneralTitle>
      <S.GeneralSubTitle align="left">Quem somos</S.GeneralSubTitle>
      <S.GeneralText>
        O endereço do nosso site é:
        <S.GeneralLink to="/">http://aprendendodesenhar.com</S.GeneralLink>
      </S.GeneralText>
      <S.GeneralSubTitle align="left">
        Mídia incorporada de outros sites
      </S.GeneralSubTitle>
      <S.GeneralText>
        Artigos neste site podem incluir conteúdo incorporado como, por exemplo,
        vídeos, imagens, artigos, etc. Conteúdos incorporados de outros sites se
        comportam exatamente da mesma forma como se o visitante estivesse
        visitando o outro site.
      </S.GeneralText>
      <S.GeneralText>
        Estes sites podem coletar dados sobre você, usar cookies, incorporar
        rastreamento adicional de terceiros e monitorar sua interação com este
        conteúdo incorporado, incluindo sua interação com o conteúdo incorporado
        se você tem uma conta e está conectado com o site.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">
        Com quem compartilhamos seus dados
      </S.GeneralSubTitle>
      <S.GeneralText>
        Não vendemos e não compartilhados dados com nenhuma outra empresa ou
        entidade. Até porque não coletamos dados de usuários.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">
        Remarketing do Google Ads, Facebook Ads e outros
      </S.GeneralSubTitle>
      <S.GeneralText>
        Nós utilizamos o recurso de Remarketing do Google Ads para veicular
        anúncios em sites de parceiros do Google. Este recurso permite
        identificar que você visitou o nosso site e assim o Google pode exibir o
        nosso anúncio para você em diferentes websites.
      </S.GeneralText>
      <S.GeneralText>
        O mesmo se aplica a plataforma de anúncios do Facebook, o Facebook Ads,
        bem como para outras plataformas de anúncios.
      </S.GeneralText>
      <S.GeneralText>
        Diversos fornecedores de terceiros, inclusive o Google, compram espaços
        de publicidade em sites da Internet. Nós eventualmente contratamos o
        Google para exibir nossos anúncios nesses espaços. Para identificar a
        sua visita no nosso site, tanto outros fornecedores de terceiros, quanto
        o Google, utilizam-se de cookies, de forma similar ao exposto na seção
        “Como usamos cookies”. Você pode desativar o uso de cookies pelo Google
        acessando o Gerenciador de preferências de anúncio.
      </S.GeneralText>
    </S.GeneralWrapper>
    <DicaExtra />
  </Layout>
)

export default PolicesPage
